import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '../tools/withStyles';
import { Link } from '../components/Link';
import { Main } from '../components/Main';
import { Post } from '../components/Post';
import { Secuence } from '../components/Secuence';
import { Text } from '../components/Text';
import { Fader } from '../components/Fader';
import profilePic from '../images/profile.jpg';
import { Button } from '../components/Button';
import dubaiFotage from '../images/dubai.mp4';
import h4ck3r from '../images/h4ck3r-nessgraphics.mp4';
import hologram from '../images/taravolta.mp4';
import {styles } from '../layouts/pages/pages.styles';
import { Cyberbtn } from '../components/Cyberbtn';





class contact extends React.Component {
  static propTypes = {
    classes: PropTypes.object
  };


  render () {
    const { classes } = this.props;

    return (
      <Main className={classes.root}>
        <Secuence stagger>
          <header>
            <h1><Text>contact information</Text></h1>
          </header>
          
            <Fader className={classes.dataContainer}>
             
                <div className={`${classes.videoContainer} ${classes.contactVideo} ${classes.contactClipPath}`}>
 
                  <video className={`${classes.video} ${classes.contactClipPath}`} preload="metadata" playsInline muted  autoPlay loop preload="metadata"  >
                    <source src={h4ck3r} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>

                </div>

                <div className={classes.data}>
                <h3><Text>Current Location: Dubai, UAE</Text></h3>
                <Text>Let's create something together, Drop me a line!</Text>
                <br />
                
                <Link href='mailto:mu.ramadan@outlook.com'target='email'><Button className={classes.contactBtn}>mu.rammadan@outlook.com</Button></Link> <br></br>
                <Link href='tel:+2010678989294'target='phone'><Button className={classes.contactBtn}>Mobile: +971528478033</Button></Link> <br></br>
                <Link href='skype:live:mu.ramadan?call'target='skype'><Button className={classes.contactBtn}>Skype: live:mu.ramadan</Button></Link> <br></br>
                <Link href='https://api.whatsapp.com/send?phone=971528478033&text=Hi%2C+%F0%9F%91%8B+&lang=en' target='whatsapp'><Button className={classes.contactBtn}>Leave Message (WhatsApp)</Button></Link>
  
                </div>

            </Fader>

            
          <p className={classes.seeMore}>
          <Text>Github</Text>
            {' '}
            <Link href='https://github.com/mu-ramadan' target='github'><Text>Profile</Text></Link>
          </p>
        </Secuence>
      </Main>
    );
  }
}

export default withStyles(styles)(contact);
